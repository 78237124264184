import './SearchResults.css';
import ContentPageWithBackButton from './ContentPageWithBackButton.js'
import React, { useEffect, useState } from "react";
import SyllabusPage from './SyllabusPage.js';

export default function SearchResultsControl({backToLogin, subjects, Results, saveState, revisions, onRevisionsUpdate}) {
    const [LoadContent, setLoadContent] = useState(null);
    const [resultslist, setResultsList] = useState(transformResult(Results));
    const [scrollPosition, setScrollPosition] = useState(0);
    // we need filtered Syllabus pages components 
    // On click on item in syllabus page componenets we nned Content page with back button 
    // On back button restore search results page with syllabus page components.

    function transformResult(results)
    {
        // for search everything is like active.
        let transformedData = Results.hits.map((hit, index) => ({
            TopicKeyword: hit._id.split('_')[0],
            IsActive: true,
            Type: hit._source.type,
            OrderId: index + 1,
            Topic: hit._id.split('_')[1],
            highlight: hit.highlight.content.join(' ')
        }));
        return transformedData;
    }
    const keywordMap = new Map();
    useEffect(() => {
        // todo:: count of number of search is also present in result.
        // todo type of content
        setResultsList(transformResult(Results));
        setLoadContent(null);
    }, [Results])

    function onLoadContent(subject, TK, Topic, type)
    {   
        const scrollPosition = window.scrollY;
        setScrollPosition( scrollPosition);
        // todo:: search for pdf also.
        setLoadContent({
            subject: subject, 
            TK: TK, 
            Topic:Topic,
            Type: type
          });
    }
    function onBackButtonFromContent()
    {
        setLoadContent(null);
        setTimeout(() => {
            window.scrollTo(0, scrollPosition);
         }, 30);
    }
    function searchTopic(topicelement, TK)
    {
       return Object.values(topicelement)[0].toLowerCase()===TK.toLowerCase();
    }
   
    function MatchedSubjects(TK)
    {
            var x =  subjects.filter((subject) => {
            return subject.Syllabus.some((topicelement) =>  searchTopic(topicelement, TK))})
        return x;
    }
    // Todo:: log un matched queries.
    return (
        <div>
            { LoadContent===null && resultslist && ( Object.keys(resultslist).length!==0 ? <div className='MainPage'>{
                resultslist.map((result) => (
                    MatchedSubjects(result.TopicKeyword).map((sub) => (
                        keywordMap.has(result.TopicKeyword) ? <></> : ( keywordMap.set(result.TopicKeyword, true) !== null ? 
                        <SyllabusPage key={result.TopicKeyword} backToLogin={backToLogin} subject={sub} loadContentPage={onLoadContent} TKfilter={result.TopicKeyword} TopicsInTK={resultslist} revisions={revisions.filter(revision => revision.Navigation.startsWith(sub.SubjectName))}/>
                        : <></>)
                    // iterate on all the subjects to find a given topic.
                    ))
                ))
                    }
                </div> :<div className='MainPage'><h1 className="SPh1">Your search did not match any Topics</h1></div> )
            }
            {
                LoadContent && <ContentPageWithBackButton backToLogin={backToLogin} tK={LoadContent.TK} topic={LoadContent.Topic} type={LoadContent.Type} subject={LoadContent.subject} saveState={saveState} revisions={revisions} onBackButton={onBackButtonFromContent} LoadContentPageForNextPrev={onLoadContent} onRevisionsUpdate={onRevisionsUpdate} />
            }

        </div>
    )
    
}


